import { createSlice } from '@reduxjs/toolkit'
import guenoApi from '../../services/guenoApi'

const buildTransactionsParams = (query = {}) => {
  const {
    fromDate,
    toDate,
    ruleId,
    transactionId,
    transactionType,
    transactionState,
    originUserId,
    destinationUserId,
    originCurrency,
    destinationCurrency,
    originMethod,
    destinationMethod,
    ruleInstanceId,
    shadowRuleInstanceId,
    maxRuleAction,
    transactionKey,
    transactionValue,
    documentNumber,
    firstName,
    lastName,
    legalName,
  } = query

  const params = {
    ...(ruleId?.length && { ruleId }),
    ...(transactionId && { transactionId }),
    ...(transactionType && { transactionType }),
    ...(transactionState && { transactionState }),
    ...(originUserId && { originUserId }),
    ...(destinationUserId && { destinationUserId }),
    ...(originCurrency !== null && { originCurrency: originCurrency.aka }),
    ...(destinationCurrency !== null && { destinationCurrency: destinationCurrency.aka }),
    ...(originMethod !== null && { originMethod }),
    ...(destinationMethod !== null && { destinationMethod }),
    ...(ruleInstanceId && { ruleInstanceId }),
    ...(shadowRuleInstanceId && { shadowRuleInstanceId }),
    ...(maxRuleAction && { maxRuleAction }),
    ...(transactionKey && { key: transactionKey }),
    ...(transactionValue && { value: transactionValue }),
    ...(documentNumber && { documentNumber }),
    ...(firstName && { firstName }),
    ...(lastName && { lastName }),
    ...(legalName && { legalName }),
  }

  if (fromDate && toDate) {
    params.fromDate = fromDate
    params.toDate = toDate
  }

  return params
}

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState: {
    transactions: [],
    transactionDetails: {},
    transactionByUserId: {},
    transactionDetailsOrigin: {},
    transactionDetailsDestination: {},
    annualTransactions: [],
    monthTransactions: [],
    filters: {
      viewMode: true,
      page: 0,
      limit: 10,
      offset: 0,
      transactionId: '',
      transactionType: null,
      transactionState: null,
      originUserId: null,
      destinationUserId: null,
      fromDate: null,
      toDate: '',
      originCurrency: null,
      destinationCurrency: null,
      originMethod: null,
      destinationMethod: null,
      ruleId: '',
      ruleInstanceId: '',
      shadowRuleInstanceId: '',
      maxRuleAction: '',
      transactionKey: '',
      transactionValue: '',
      documentNumber: '',
      firstName: '',
      lastName: '',
      legalName: '',
    },
  },
  reducers: {
    updateTransactions: (state, transactions) => {
      state.transactions = transactions.payload
    },
    updateTransactionDetails: (state, details) => {
      state.transactionDetails = details.payload
    },
    updateTransactionByUserId: (state, details) => {
      state.transactionByUserId = details.payload
    },
    updateTransactionDetailsOrigin: (state, details) => {
      state.transactionDetailsOrigin = details.payload
    },
    updateTransactionDetailsDestination: (state, details) => {
      state.transactionDetailsDestination = details.payload
    },
    updateAnnualTransactions: (state, annualTransactions) => {
      state.annualTransactions = annualTransactions.payload
    },
    updateMonthTransactions: (state, monthTransactions) => {
      state.monthTransactions = monthTransactions.payload
    },
    performLogoutTransactions: (state) => {
      state.transactions = []
      state.annualTransactions = []
      state.monthTransactions = []
      state.transactionDetails = {}
      state.transactionByUserId = {}
      state.transactionDetailsOrigin = {}
      state.transactionDetailsDestination = {}
    },
    updateTransactionsFilters: (state, filters) => {
      state.filters = filters.payload
    },
  },
})

const transactionsApi = guenoApi.injectEndpoints({
  endpoints: (builder) => ({
    findTransactions: builder.query({
      query: ({ emailClient, viewMode, limit, offset, page, sortField, sortDirection, ...query }) => {
        const searchParams = buildTransactionsParams(query)

        return {
          url: `/kyt/transactions/${emailClient}`,
          params: {
            viewMode,
            limit,
            offset,
            page,
            sortField,
            sortDirection,
            ...searchParams,
          },
        }
      },
    }),
  }),
})

export const {
  updateTransactions,
  updateTransactionDetails,
  updateTransactionDetailsOrigin,
  updateTransactionDetailsDestination,
  updateAnnualTransactions,
  updateMonthTransactions,
  performLogoutTransactions,
  updateTransactionsFilters,
  updateTransactionByUserId,
} = transactionSlice.actions

export const { useFindTransactionsQuery } = transactionsApi

export default transactionSlice.reducer
